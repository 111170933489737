<template>
  <el-container style="height:calc(100vh);">
    <el-header>
      <span class="left el-icon-arrow-left" @click="returnOn">优惠设置</span>
      优惠折扣设置
    </el-header>

    <el-main class="main">
      <div class="content">
        <div class="box">
          <div class="cell-t">
            <div class="title">
              整单折扣
            </div>
            <div class="value">
              <el-switch v-model="switchValue1" active-color="#409eff">
              </el-switch>
            </div>
          </div>
          <div class="list">
            <div class="list-item">
              <div class="item-l">
                <span>消费金额：</span><b>1000.00</b>元
              </div>
              <div class="item-c">
                <span>折扣比例：</span>9.0折
              </div>
              <div class="item-r">
                <span class="el-icon-edit-outline" @click="discountShow= true"></span>
                <span class="el-icon-circle-close"></span>
              </div>
            </div>
            <div class="list-item">
              <div class="item-l">
                <span>消费金额：</span><b>2000.00</b>元
              </div>
              <div class="item-c">
                <span>折扣比例：</span>8.0折
              </div>
              <div class="item-r">
                <span class="el-icon-edit-outline" @click="discountShow= true"></span>
                <span class="el-icon-circle-close"></span>
              </div>
            </div>
            <div class="list-item">
              <div class="item-l">
                <span>消费金额：</span><b>3000.00</b>元
              </div>
              <div class="item-c">
                <span>折扣比例：</span>7.0折
              </div>
              <div class="item-r">
                <span class="el-icon-edit-outline" @click="discountShow= true"></span>
                <span class="el-icon-circle-close"></span>
              </div>
            </div>
          </div>
          <div class="add el-icon-plus" @click="discountShow=true"></div>
        </div>
        <div class="box">
          <div class="cell-t">
            <div class="title">
              整单立减
            </div>
            <div class="value">
              <el-switch v-model="switchValue2" active-color="#409eff">
              </el-switch>
            </div>
          </div>
          <div class="list">
            <div class="list-item">
              <div class="item-l">
                <span>消费金额：</span><b>1000.00</b>元
              </div>
              <div class="item-c">
                <span>整单立减：</span><b>100.00</b>元
              </div>
              <div class="item-r">
                <span class="el-icon-edit-outline" @click="priceShow= true"></span>
                <span class="el-icon-circle-close"></span>
              </div>
            </div>
            <div class="list-item">
              <div class="item-l">
                <span>消费金额：</span><b>2000.00</b>元
              </div>
              <div class="item-c">
                <span>整单立减：</span><b>200.00</b>元
              </div>
              <div class="item-r">
                <span class="el-icon-edit-outline" @click="priceShow= true"></span>
                <span class="el-icon-circle-close"></span>
              </div>
            </div>
            <div class="list-item">
              <div class="item-l">
                <span>消费金额：</span><b>3000.00</b>元
              </div>
              <div class="item-c">
                <span>整单立减：</span><b>300.00</b>元
              </div>
              <div class="item-r">
                <span class="el-icon-edit-outline" @click="priceShow= true"></span>
                <span class="el-icon-circle-close"></span>
              </div>
            </div>
          </div>
          <div class="add el-icon-plus" @click="priceShow=true"></div>
        </div>
      </div>
    </el-main>
    <el-dialog title="整单折扣设置" center :visible.sync="discountShow" width="40%" :before-close="handleClose">
      <el-alert title="查折扣比例支持的范围（0.1-9.9）" type="warning" show-icon :closable="false">
      </el-alert>
      <el-form label-width="80px" :model="discountForm">
        <el-form-item label="消费金额">
          <el-input v-model="discountForm.price" placeholder="输入消费金额"></el-input>
        </el-form-item>
        <el-form-item label="折扣比例">
          <el-input v-model="discountForm.discount" placeholder="例如9折，请输入数字9"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="discountShow = false">取 消</el-button>
        <el-button type="primary" @click="discountShow = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="整单立减设置" center :visible.sync="priceShow" width="40%" :before-close="handleClose">
      <el-alert title="最大优惠金额不得超过1000元" type="warning" show-icon :closable="false">
      </el-alert>
      <el-form label-width="80px" :model="priceForm">
        <el-form-item label="消费金额">
          <el-input v-model="priceForm.price" placeholder="输入消费金额"></el-input>
        </el-form-item>
        <el-form-item label="优惠金额">
          <el-input v-model="priceForm.discount" placeholder="输入优惠金额"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="priceShow = false">取 消</el-button>
        <el-button type="primary" @click="priceShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        switchValue1: true,
        switchValue2: true,
        malingRadio: '抹分',
        discountShow: false,
        priceShow: false,
        discountForm: {
          price: '',
          discount: '',
        },
        priceForm: {
          price: '',
          discount: '',
        }
      }
    },
    created() {},
    methods: {
      // 返回上级
      returnOn() {
        this.$router.push({
          name: 'PreferentialSet'
        })
      },
      handleClose() {
        this.discountShow = false
        this.priceShow = false
        this.discountForm.price = ''
        this.discountForm.discount = ''
        this.priceForm.price = ''
        this.priceForm.discount = ''
      }
    }
  };

</script>
<style lang="less" scoped>
  .el-header {
    position: relative;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      display: flex;
      align-items: center;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      color: #666;
      line-height: 19px;

      &::before {
        color: #46a6ff;
        font-size: 18px;
        line-height: 18px;
      }
    }
  }

  .main {
    background-color: #f9f9f9;
  }

  .content {
    padding: 20px;

    .box {
      box-shadow: 0 0 2px #ccc;
      padding: 10px 20px;
      background-color: #fff;
      margin-bottom: 15px;

      .cell-t {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        .title {
          line-height: 24px;
        }
      }

      .list {
        color: #666;

        .list-item {
          display: flex;
          justify-content: space-around;
          align-items: center;
          border: 1px solid #f2f2f2;
          padding: 15px 0;
          padding-left: 20px;
          margin-top: 10px;

          &:first-child {
            margin-top: 0;
          }


          .item-l,
          .item-c {
            width: 40%;
            text-align: left;

            span {
              color: #999;
            }
          }

          .item-r {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 20%;
            font-size: 18px;
            color: #ccc;
          }

        }
      }

      .add {
        width: 100%;
        text-align: center;
        line-height: 50px;
        border: 1px dashed #ccc;
        margin-top: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #999;
      }
    }

  }

  /deep/ .el-dialog {
    min-width: 400px;

    .el-form {
      margin-top: 20px;

      .el-form-item {
        border: 1px solid #dcdfe6;
        border-radius: 5px;

        .el-form-item__content {
          .el-input {
            .el-input__inner {
              text-align: right;
              border: none;
            }
          }
        }
      }
    }
  }

</style>
